// react
import { useState, useEffect } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { Box, Drawer, Divider, Stack, IconButton } from '@mui/material'
import { MenuOutlined } from '@mui/icons-material'
// hooks
import { useLingui } from '@lingui/react'
// components
import Logo from '~/components/Logo'

// i18n
import LanguageSwitcher from '~/components/LanguageSwitcher'
import { NavSectionVertical } from '~/components/nav-section'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object[]} props.navConfig
 * @returns {JSX.Element}
 */
export default function MenuMobile({ navConfig }) {
  const { pathname } = useRouter()

  // i18n
  const { _ } = useLingui()

  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  return (
    <>
      <IconButton
        variant='outlined'
        size='small'
        onClick={handleDrawerOpen}
      >
        <MenuOutlined />
      </IconButton>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: '24ch' } }}
      >
        <Box py={2}>
          <Box px={3}>
            <Logo />

            <Stack
              spacing={1}
              marginTop={1.5}
            >
              <LanguageSwitcher isFullWidth />
            </Stack>
          </Box>

          <Box
            marginY={1}
            paddingX={3}
          >
            <Divider />
          </Box>

          <Box marginX={-1}>
            <NavSectionVertical
              navConfig={navConfig}
              isGrouped={false}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

// ----------------------------------------------------------------------
