// react
import { useMemo } from 'react'
// next
import { useRouter } from 'next/router'
import NextLink from 'next/link'
// @mui
import { Tooltip } from '@mui/material'
// style
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style'

// ----------------------------------------------------------------------

const TooltipWrapper = ({ children, caption }) => {
  if (!caption) return children

  return (
    <Tooltip
      title={caption}
      arrow
      placement='right'
    >
      {children}
    </Tooltip>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.item
 * @returns {JSX.Element}
 */
export default function NavItem({ item, ...other }) {
  const { pathname } = useRouter()

  // detect active link
  const isActive = useMemo(() => {
    return pathname.startsWith(item.path) && !item.isActiveExclusion?.(pathname)
  }, [item, pathname])

  const { title, icon, disabled, caption } = item

  const renderContent = (
    <ListItemStyle
      as={NextLink}
      active={isActive}
      disabled={disabled}
      href={item.path}
      {...other}
    >
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}

      <TooltipWrapper caption={caption}>
        <ListItemTextStyle
          primary={title}
          primaryTypographyProps={{
            noWrap: true,
            variant: isActive ? 'subtitle2' : 'body2'
          }}
        />
      </TooltipWrapper>
    </ListItemStyle>
  )

  return <>{renderContent}</>
}
