// @mui
import { List, Stack } from '@mui/material'
//
import { ListSubheaderStyle } from './style'
import NavItem from './NavItem'
import { Fragment } from 'react'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.isGrouped
 * @param {Object[]} props.navConfig
 * @returns {JSX.Element}
 */
export default function NavSectionVertical({
  navConfig,
  isGrouped = true,
  ...other
}) {
  return (
    <Stack
      spacing={1}
      {...other}
    >
      <List
        disablePadding
        sx={{ px: 2 }}
      >
        {isGrouped
          ? navConfig.map((group) => (
              <Fragment key={group.subheader}>
                <ListSubheaderStyle
                  disableGutters
                  sx={{ paddingY: 0.5 }}
                >
                  {group.subheader}
                </ListSubheaderStyle>

                {group.items.map((item) => (
                  <NavItem
                    key={item.title + item.path}
                    item={item}
                  />
                ))}
              </Fragment>
            ))
          : navConfig.map((item) => (
              <NavItem
                key={item.title + item.path}
                item={item}
              />
            ))}
      </List>
    </Stack>
  )
}
