// hooks
import { IMAGE_CDN_URL } from '~/config'
import useAuth from '../hooks/useAuth'
// utils
import createAvatar from '../libs/createAvatar'
//
import Avatar from './Avatar'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function MyAvatar({ ...other }) {
  const { user } = useAuth()

  return (
    <Avatar
      src={
        user.photoURL
          ? user.photoURL.startsWith('https://') ||
            user.photoURL.startsWith('http://')
            ? user.photoURL
            : `${IMAGE_CDN_URL}/${user.photoURL}`
          : null
      }
      alt={user?.name}
      color={user?.photoURL ? 'default' : createAvatar(user?.name).color}
      {...other}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  )
}
