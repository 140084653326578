// react
import { forwardRef } from 'react'
// @mui
import { Box, Tooltip, ListItemText, ListItemIcon } from '@mui/material'
// config
import { ICON } from '~/config'
// components
import { ListItemStyle } from './style'
// icons
import { ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.item
 * @param {number} props.depth
 * @param {boolean} props.active
 * @param {boolean} props.open
 * @returns {JSX.Element}
 */
const NavItem = forwardRef(({ item, depth, active, open, ...other }, ref) => {
  const { title, icon, info, children, disabled, caption /* , roles */ } = item

  const renderContent = (
    <ListItemStyle
      ref={ref}
      open={open}
      depth={depth}
      active={active}
      disabled={disabled}
      {...other}
    >
      {icon && (
        <ListItemIcon
          sx={{
            mr: 1,
            flexShrink: 0,
            width: ICON.NAVBAR_ITEM_HORIZONTAL,
            height: ICON.NAVBAR_ITEM_HORIZONTAL
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      <ListItemText
        primary={title}
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2'
        }}
      />

      {caption && (
        <Tooltip
          title={caption}
          arrow
        >
          <Box
            component='span'
            sx={{ ml: 0.5, lineHeight: 0 }}
          >
            <InfoOutlined
              sx={{
                width: ICON.NAVBAR_ITEM_HORIZONTAL / -4,
                height: ICON.NAVBAR_ITEM_HORIZONTAL / -4
              }}
            />
          </Box>
        </Tooltip>
      )}

      {info && (
        <Box
          component='span'
          sx={{ ml: 1, lineHeight: 0 }}
        >
          {info}
        </Box>
      )}

      {Boolean(children) &&
        (depth > 1 ? (
          <ExpandMore
            sx={{
              ml: 0.5,
              flexShrink: 0,
              width: ICON.NAVBAR_ITEM_HORIZONTAL,
              height: ICON.NAVBAR_ITEM_HORIZONTAL
            }}
          />
        ) : (
          <ExpandLess
            sx={{
              ml: 0.5,
              flexShrink: 0,
              width: ICON.NAVBAR_ITEM_HORIZONTAL,
              height: ICON.NAVBAR_ITEM_HORIZONTAL
            }}
          />
        ))}
    </ListItemStyle>
  )

  return <>{renderContent}</>
})

NavItem.displayName = 'NavItem'

export default NavItem
