// hooks
import useAuth from '~/hooks/useAuth'
// i18n
import { useLingui } from '@lingui/react'
// config
import navConfig from '../NavConfig'

// ----------------------------------------------------------------------

/** Get role based navConfig
 *  @returns {Object[]}
 */
export default function useNavConfig() {
  const { user } = useAuth()

  // i18n
  const { i18n } = useLingui()

  const updatedRoleNavConfig = [...navConfig.clicky(i18n)]

  if (user.role === 'creator') {
    updatedRoleNavConfig.push(...navConfig.creator(i18n))
    updatedRoleNavConfig.push(...navConfig.activity(i18n))
  }

  if (user.role === 'admin') {
    updatedRoleNavConfig.push(...navConfig.admin)
  }

  if (user.role === 'user') {
    updatedRoleNavConfig.push(...navConfig.user)
    updatedRoleNavConfig.push(...navConfig.activity(i18n))
  }

  updatedRoleNavConfig.push(...navConfig.account(i18n))

  return updatedRoleNavConfig
}
