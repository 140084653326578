import { throttle } from 'lodash'
import { useState, useEffect } from 'react'

/**
 * @param {int} top - threshold number in pixel or ratio (0...1)
 * @param {string} unit - `ratio` or `pixel` (default)
 * @returns
 */
export default function useOffSetTop(top = 100, unit = 'pixel') {
  const [offsetTop, setOffSetTop] = useState(false)

  useEffect(() => {
    if (typeof document === 'undefined') return

    const scrollListener = throttle(() => {
      const docHeight = document.documentElement.offsetHeight
      const windowHeight = document.documentElement.scrollHeight
      const topThreshold =
        unit === 'pixel' ? top : (windowHeight - docHeight) * top

      if (window.scrollY > topThreshold) {
        setOffSetTop(true)
      } else {
        setOffSetTop(false)
      }
    }, 500)

    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [top, unit])

  return offsetTop
}

// Usage
// const offset = useOffSetTop(100);
