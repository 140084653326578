// next
import NextLink from 'next/link'
// @mui
import { Grid, Link, Container, Typography, Stack, Box } from '@mui/material'
// components
import Logo from '~/components/Logo'
import SocialsButton from '~/components/SocialsButton'
// paths
import { PATH_PAGE } from '~/routes/paths'
// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function MainFooter() {
  const { _ } = useLingui()

  const LINKS = [
    {
      headline: null,
      children: [
        {
          name: _(msg`Ketentuan Privasi`),
          href: PATH_PAGE.legal.privacyPolicy
        },
        {
          name: _(msg`Syarat dan Ketentuan`),
          href: PATH_PAGE.legal.termsConditions
        }
      ]
    }
  ]

  return (
    <Box
      paddingTop={3}
      paddingBottom={1.5}
      borderTop='1px solid'
      borderColor='divider'
      backgroundColor='#F7F8F9'
    >
      <Container>
        <Box>
          <Logo
            nextHeight={32}
            sx={{
              height: '2rem',
              width: 'auto',
              marginBottom: 2,
              mx: { xs: 'auto', md: 'inherit' }
            }}
          />
        </Box>

        <div>
          <Grid
            container
            spacing={2}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {LINKS.map(({ headline, children }) => (
              <Grid
                item
                key={`footer-column-${headline}`}
                xs={12}
                md={2}
              >
                {headline && (
                  <Typography
                    component='p'
                    variant='overline'
                    marginBottom={1}
                  >
                    {headline}
                  </Typography>
                )}

                <Stack spacing={0.25}>
                  {children.map(({ name, href, isExternal, isNewTab }) => (
                    // eslint noopener bugging out because of isExternal conditional
                    // so, just disable error for this one
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <Link
                      component={isExternal || isNewTab ? 'a' : NextLink}
                      key={`footer-link-${name}-${href}`}
                      href={href}
                      color='inherit'
                      fontSize='small'
                      sx={{ display: 'block' }}
                      target={isExternal || isNewTab ? '_blank' : undefined}
                      rel={
                        isExternal ? 'noreferrer noopener nofollow' : undefined
                      }
                    >
                      {name}
                    </Link>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </div>

        <Box
          marginY={0.75}
          // position correction to make sure button is aligned to the right
          // despite the container's padding, which is not visible unless button hovered / focused
          position='relative'
          left='-0.5rem'
        >
          <SocialsButton displayButtons={['Instagram']} />
        </Box>

        <Typography
          component='p'
          fontSize='small'
          color='text.secondary'
        >
          {_(msg`©2023 - 2024. Hak cipta dilindungi undang-undang.`)}
        </Typography>
      </Container>
    </Box>
  )
}
