// components
import MainLayout from './main'
import DashboardLayout from './dashboard'
import LogoOnlyLayout from './LogoOnlyLayout'
import BlankLayout from './BlankLayout'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} [props.variant='dashboard']
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
export default function Layout({ variant = 'dashboard', children, ...rest }) {
  if (variant === 'logoOnly') {
    return <LogoOnlyLayout {...rest}> {children} </LogoOnlyLayout>
  }

  if (variant === 'main') {
    return <MainLayout {...rest}>{children}</MainLayout>
  }

  if (variant === 'blank') {
    return <BlankLayout {...rest}>{children}</BlankLayout>
  }

  return <DashboardLayout {...rest}> {children} </DashboardLayout>
}
