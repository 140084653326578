// @mui
import { Link, Stack, Button, Tooltip, IconButton } from '@mui/material'
// components
import NextImage from '~/components/NextImage'

// ----------------------------------------------------------------------

import InstagramIcon from '~/assets/social-icons/instagram.png'
import TiktokIcon from '~/assets/social-icons/tiktok.png'
import YouTubeIcon from '~/assets/social-icons/youtube.png'
import LinkedinIcon from '~/assets/social-icons/linkedin.png'
import TwitterIcon from '~/assets/social-icons/twitter.png'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.simple
 * @param {Object} props.sx
 * @returns {JSX.Element}
 */
export default function SocialsButton({
  simple = true,
  sx,
  displayButtons = [],
  ...other
}) {
  const SOCIALS = [
    {
      name: 'Tiktok',
      image: TiktokIcon,
      path: '#'
    },
    {
      name: 'Instagram',
      image: InstagramIcon,
      path: 'https://www.instagram.com/clicky_creator/'
    },
    {
      name: 'Linkedin',
      image: LinkedinIcon,
      path: '#'
    },
    {
      name: 'Twitter',
      image: TwitterIcon,
      path: '#'
    },
    {
      name: 'YouTube',
      image: YouTubeIcon,
      path: '#'
    }
  ]

  if (!displayButtons || displayButtons.length <= 0) return null

  return (
    <Stack
      direction='row'
      flexWrap='wrap'
      justifyContent={{ xs: 'center', md: 'flex-start' }}
      alignItems='center'
    >
      {SOCIALS.filter(({ name }) => displayButtons.includes(name)).map(
        (social) => {
          const { name, image, path } = social

          return simple ? (
            <Link
              key={name}
              href={path}
              target='_blank'
              rel='noreferrer noopener'
            >
              <Tooltip
                title={name}
                placement='top'
              >
                <IconButton
                  color='inherit'
                  {...other}
                >
                  <NextImage
                    src={image}
                    alt={name}
                    nextWidth={24}
                    nextHeight={24}
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      objectFit: 'contain',
                      objectPosition: 'center'
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Link>
          ) : (
            <Button
              key={name}
              href={path}
              color='inherit'
              variant='outlined'
              size='small'
              startIcon={
                <NextImage
                  src={image}
                  alt={name}
                  nextWidth={16}
                  nextHeight={16}
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    objectFit: 'contain',
                    objectPosition: 'center'
                  }}
                />
              }
              sx={{ m: 0.5, flexShrink: 0, ...sx }}
              {...other}
            >
              {name}
            </Button>
          )
        }
      )}
    </Stack>
  )
}
