import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------
const directionMap = {
  top: 'to top',
  right: 'to right',
  bottom: 'to bottom',
  left: 'to left'
}

function getDirection(value = 'bottom') {
  return directionMap[value]
}

// ----------------------------------------------------------------------

/**
 * @param {Object} theme
 * @returns {Object}
 */
export default function cssStyles(theme) {
  const bgBlur = (props) => {
    const color = props?.color || theme?.palette.background.default || '#000000'
    const blur = props?.blur || 6
    const opacity = props?.opacity || 0.8

    return {
      backdropFilter: `blur(${blur}px)`,
      WebkitBackdropFilter: `blur(${blur}px)`, // Fix on Mobile
      backgroundColor: alpha(color, opacity)
    }
  }

  /**
   * @param {string} props.direction - gradient direction
   * @param {string} props.startColor - start color
   * @param {string} props.endColor - end color
   * @returns
   */
  const bgGradient = (props) => {
    const direction = getDirection(props?.direction)
    const startColor = props?.startColor || `${alpha('#000000', 0)} 0%`
    const endColor = props?.endColor || '#000000 75%'

    return {
      backgroundImage: `linear-gradient(${direction}, ${startColor} 50%, ${endColor});`
    }
  }

  /**
   * @param {string} props.direction - gradient direction
   * @param {string} props.startColor - start color
   * @param {string} props.endColor - end color
   * @returns
   */
  const textGradient = (props) => {
    return {
      ...bgGradient(props),
      color: 'transparent',
      backgroundClip: 'text'
    }
  }

  return {
    bgBlur,
    bgGradient,
    textGradient
  }
}
