// react
import { forwardRef } from 'react'
// next
import NextLink from 'next/link'
// component
import NextImage from './NextImage'
// paths
import { PATH_PAGE } from '~/routes/paths'

// images
import ClickyLogo from '~/assets/logo/clicky-logo.png'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} [props.disabledLink=false]
 * @param {Object} props.sx
 * @param {Object} ref
 * @returns {JSX.Element}
 */
const Logo = forwardRef(({ disabledLink = false, sx, ...rest }, ref) => {
  const logo = (
    <NextImage
      ref={ref}
      src={ClickyLogo}
      alt='Clicky Logo'
      nextHeight={40}
      sx={{
        width: 'auto',
        height: { xs: '2rem', sm: '2.5rem' },
        objectFit: 'contain',
        objectPosition: 'center',
        cursor: 'pointer',
        ...sx
      }}
      {...rest}
    />
  )

  if (disabledLink) {
    return logo
  }

  return <NextLink href={PATH_PAGE.landing}>{logo}</NextLink>
})

Logo.displayName = 'Logo'

export default Logo
