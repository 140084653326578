// react
import { useMemo } from 'react'
// hooks
import useAuth from '~/hooks/useAuth'
// paths
import { PATH_APP, PATH_PAGE } from '~/routes/paths'
// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// component specific
import {
  CollectionsBookmark,
  DashboardOutlined,
  HistoryOutlined,
  HomeOutlined,
  Inventory2Outlined
} from '@mui/icons-material'

// ----------------------------------------------------------------------

/**
 * @returns {Array}
 */
export default function useMenuConfig() {
  const { isAuthenticated, user } = useAuth()
  const { _ } = useLingui()

  const appliedMenu = useMemo(() => {
    const publicMenu = [
      {
        title: _(msg`Beranda`),
        icon: <HomeOutlined />,
        path: PATH_PAGE.landing
      },
      {
        title: _(msg`Showcase`),
        icon: <CollectionsBookmark />,
        path: PATH_PAGE.showcase,
        isFeatured: true
      }
    ]

    const userMenu = [
      {
        title: _(msg`Akses Konten`),
        icon: <Inventory2Outlined />,
        path: PATH_APP.activity.contentAccess
      },
      {
        title: _(msg`Riwayat Transaksi`),
        icon: <HistoryOutlined />,
        path: PATH_APP.activity.transactionHistory
      }
    ]

    const creatorMenu = [
      {
        title: _(msg`Dashboard Kreator`),
        path: PATH_APP.root,
        icon: <DashboardOutlined />
      }
    ]

    const adminMenu = [
      {
        title: _(msg`Dashboard Admin`),
        path: PATH_APP.root,
        icon: <DashboardOutlined />
      }
    ]

    if (user?.role === 'admin') {
      return [...publicMenu, ...adminMenu]
    }

    if (user?.role === 'creator') {
      return [...publicMenu, ...userMenu, ...creatorMenu]
    }

    if (isAuthenticated) {
      return [...publicMenu, ...userMenu]
    }

    return [...publicMenu]
  }, [isAuthenticated, user, _])

  return appliedMenu
}
