// next
import { useRouter } from 'next/router'
// next
import NextLink from 'next/link'
// @mui
import { styled, useTheme } from '@mui/material/styles'
import { Box, Button, AppBar, Toolbar, Container, Stack } from '@mui/material'
// hooks
import useOffSetTop from '~/hooks/useOffSetTop'
import useResponsive from '~/hooks/useResponsive'
// utils
import cssStyles from '~/libs/cssStyles'
// config
import { HEADER } from '~/config'
import { PATH_AUTH } from '~/routes/paths'
// components
import Logo from '~/components/Logo'
import AccountPopover from '~/components/AccountPopover'
// hooks
import useAuth from '~/hooks/useAuth'

//
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import useMenuConfig from './hooks/useMenuConfig'

// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT
  }
}))

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function MainHeader() {
  const { user } = useAuth()
  const isOffset = useOffSetTop(1)
  const navConfig = useMenuConfig()

  // i18n
  const { _ } = useLingui()

  const theme = useTheme()

  const { pathname } = useRouter()

  const isDesktop = useResponsive('up', 'md')

  const isHome = pathname === '/'

  return (
    <Box sx={{ zIndex: 'appBar' }}>
      <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
        <ToolbarStyle
          disableGutters
          sx={{
            ...(isOffset && {
              ...cssStyles(theme).bgBlur(),
              height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 }
            })
          }}
        >
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Stack
              direction='row'
              spacing={{ xs: 1, md: 2 }}
              sx={{
                flexWrap: 'nowrap',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Logo
                priority
                sx={!isDesktop && { height: '1.85rem' }}
              />

              <Box sx={{ flexGrow: 1 }} />

              {isDesktop && navConfig?.length > 0 && (
                <MenuDesktop
                  isOffset={isOffset}
                  isHome={isHome}
                  navConfig={navConfig}
                />
              )}

              {user?._id ? (
                <AccountPopover />
              ) : (
                <>
                  <Button
                    component={NextLink}
                    href={PATH_AUTH.signIn()}
                    variant='outlined'
                    size='small'
                    sx={{
                      fontSize: { xs: '0.75rem', sm: 'small' },
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {_(msg`Masuk`)}
                  </Button>

                  <Button
                    component={NextLink}
                    href={PATH_AUTH.signUp}
                    variant='contained'
                    color='primary'
                    size='small'
                    sx={{
                      fontSize: { xs: '0.75rem', sm: 'small' },
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {_(msg`Daftar`)}
                  </Button>
                </>
              )}

              {!isDesktop && navConfig?.length > 0 && (
                <MenuMobile
                  isOffset={isOffset}
                  isHome={isHome}
                  navConfig={navConfig}
                />
              )}
            </Stack>
          </Container>
        </ToolbarStyle>
      </AppBar>
    </Box>
  )
}
