// @mui
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
// hooks
import useCollapseDrawer from '~/hooks/useCollapseDrawer'
// config
import { NAVBAR } from '~/config'
//
import NavbarVertical from './navbar/NavbarVertical'

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick'
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3, 2),
  backgroundColor: theme.palette.grey[100],
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(6, 4),
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH
    })
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(9, 6)
  }
}))

// ----------------------------------------------------------------------

/**
 * Page layout for dashboard
 * @param {React.ReactNode} children
 * @returns {JSX.Element}
 */
export default function DashboardLayout({ children }) {
  const { collapseClick } = useCollapseDrawer()

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 }
      }}
    >
      <NavbarVertical />

      <MainStyle collapseClick={collapseClick}>{children}</MainStyle>
    </Box>
  )
}
