// react
import { useEffect, useState } from 'react'
// next
import { useRouter } from 'next/router'

// @mui
import { styled } from '@mui/material/styles'
import { Box, Stack, Drawer, Fab } from '@mui/material'
import { CloseOutlined, MenuOutlined } from '@mui/icons-material'
// hooks
import useResponsive from '~/hooks/useResponsive'
import useCollapseDrawer from '~/hooks/useCollapseDrawer'
// config
import { NAVBAR } from '~/config'
// components
import Logo from '~/components/Logo'
import LanguageSwitcher from '~/components/LanguageSwitcher'
import { NavSectionVertical } from '~/components/nav-section'

// layout specific
import NavbarDocs from './NavbarDocs'
import NavbarAccount from './NavbarAccount'
import CollapseButton from './CollapseButton'

// component specific
import useNavConfig from './hooks/useNavConfig'

// i18n
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.standard
    })
  }
}))

// ----------------------------------------------------------------------

/**
 * Vertical Navbar for Dashboard Layout
 * @returns {JSX.Element}
 */
export default function NavbarVertical() {
  const { pathname } = useRouter()
  const navConfig = useNavConfig()

  const isDesktop = useResponsive('up', 'lg')

  // mobile sidebar
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const { _ } = useLingui()

  const handleToggleSidebar = () => setIsOpenSidebar((prev) => !prev)
  const handleCloseSidebar = () => setIsOpenSidebar(false)

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave
  } = useCollapseDrawer()

  useEffect(() => {
    if (isOpenSidebar) {
      setIsOpenSidebar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <>
      <Stack
        spacing={1.5}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' })
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        <LanguageSwitcher
          isFullWidth
          isCompact={isCollapse}
        />

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && <NavbarDocs />}
    </>
  )

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      {!isDesktop && (
        <>
          <Box
            sx={{
              position: 'fixed',
              zIndex: 'appBar',
              top: '1rem',
              right: '1rem'
            }}
          >
            <Fab
              color='primary'
              size='small'
              onClick={handleToggleSidebar}
            >
              {isOpenSidebar ? <CloseOutlined /> : <MenuOutlined />}
            </Fab>
          </Box>

          <Drawer
            open={isOpenSidebar}
            onClose={handleCloseSidebar}
            PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
          >
            {renderContent}
          </Drawer>
        </>
      )}

      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'solid',
              bgcolor: 'background.default',
              boxShadow: (theme) => theme.customShadows.z20,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                boxShadow: (theme) => theme.customShadows.z24
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}
