// react
import { forwardRef } from 'react'
// next
import Head from 'next/head'
// @mui
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.title]
 * @param {string} [props.description]
 * @param {React.ReactNode} [props.meta]
 * @returns {JSX.Element}
 */
const Page = forwardRef(
  ({ children, title = '', description, meta, ...other }, ref) => (
    <>
      <Head>
        <title>{title ? `${title} | Clicky` : 'Clicky'}</title>

        {description && (
          <meta
            name='description'
            content={description}
          />
        )}
        {meta}
      </Head>

      <Box
        ref={ref}
        {...other}
      >
        {children}
      </Box>
    </>
  )
)

Page.displayName = 'Page'

export default Page
