// next
import NextLink from 'next/link'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Link, Tooltip, Typography } from '@mui/material'
// hooks
import useAuth from '~/hooks/useAuth'
// routes
import { PATH_PAGE } from '~/routes/paths'
// components
import MyAvatar from '~/components/MyAvatar'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  })
}))

// ----------------------------------------------------------------------

/**
 * Mini profile card in dashboard navbar
 * @param {Object} props
 * @param {boolean} props.isCollapse whether the navbar is currenly collapsed
 */
export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth()

  return (
    <Link
      component={NextLink}
      href={PATH_PAGE.landing}
      underline='none'
      color='inherit'
    >
      <RootStyle sx={{ ...(isCollapse && { bgcolor: 'transparent' }) }}>
        <MyAvatar />

        <Box
          sx={{
            overflow: 'hidden',
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter
              }),
            ...(isCollapse && { ml: 0, width: 0 })
          }}
        >
          <Typography
            variant='subtitle2'
            noWrap
            sx={{ width: '100%', textOverflow: 'ellipsis' }}
          >
            {user?.name ?? `Clicky User #${user?._id}`}
          </Typography>

          <Tooltip
            title={`Id: #${user?._id}`}
            arrow
          >
            <Typography
              variant='body2'
              noWrap
              sx={{ fontSize: 'small', color: 'text.secondary' }}
            >
              {user?.email ?? `${user?._id}@user.clicky.id`}
            </Typography>
          </Tooltip>
        </Box>
      </RootStyle>
    </Link>
  )
}
