// @mui
import { Button, Stack, Typography } from '@mui/material'
// routes

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function NavbarDocs() {
  const { _ } = useLingui()
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: 'center',
        display: 'block'
      }}
    >
      <div>
        <Typography
          gutterBottom
          variant='subtitle1'
        >
          {/* Connect with Other Creator */}
          {_(msg`Terhubung dengan Kreator Lainnya`)}
        </Typography>

        <Button
          variant='outlined'
          size='small'
          LinkComponent='a'
          href='https://t.me/+SwZYs_RGN2wwMzc1'
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          {/* Join Clicky Community */}
          {_(msg`Bergabung dengan Komunitas Clicky`)}
        </Button>
      </div>
    </Stack>
  )
}
