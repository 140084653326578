// @mui
import {
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  DiscountOutlined,
  Edit,
  EqualizerOutlined,
  HistoryOutlined,
  HomeOutlined,
  InsightsOutlined,
  Inventory2Outlined,
  MeetingRoomOutlined,
  PeopleAltOutlined,
  PersonOutlined,
  ReceiptLongOutlined,
  ScheduleSendOutlined,
  SignLanguage,
  StarsOutlined
} from '@mui/icons-material'
// paths
import { PATH_AUTH, PATH_APP, PATH_PAGE } from '~/routes/paths'

// i18n
import { t } from '@lingui/macro'

// ----------------------------------------------------------------------

const clicky = (i18n) => [
  {
    subheader: 'Clicky',
    items: [
      {
        title: i18n._(t`Panduan Clicky`),
        path: PATH_APP.onboarding.setup,
        icon: <SignLanguage />
        // caption: "Let's get started by following the to-do list below"
      },
      {
        title: 'Showcase',
        path: PATH_PAGE.showcase,
        icon: <StarsOutlined />
        // caption: 'List of featured products from our creators'
      }
    ]
  }
]

const creator = (i18n) => [
  {
    subheader: i18n._(t`Kreator`),
    items: [
      {
        title: i18n._(t`Daftar Produk`),
        path: PATH_APP.product.list,
        icon: <ReceiptLongOutlined />
        // caption: 'Add new product and monitor your transaction'
      },

      {
        title: 'Main Page',
        path: PATH_APP.catalog.update,
        icon: <Edit />
        // caption:
        //   'Add any links, social media, portfolio, YouTube video and more...'
      },
      {
        title: i18n._(t`Kode Promo`),
        path: PATH_APP.promo.list,
        icon: <DiscountOutlined />
        // caption: 'Add new product and monitor your transaction'
      },
      {
        title: i18n._(t`Kirim Email`),
        path: PATH_APP.emailBlast.list,
        icon: <ScheduleSendOutlined />
      },
      {
        title: i18n._(t`Analitik`),
        path: PATH_APP.analytics.product,
        icon: <InsightsOutlined />
        // caption:
        //   'Monitor your data of traffic, transaction, conversion and revenue'
      },
      {
        title: i18n._(t`Pendapatan`),
        path: PATH_APP.earning.overview,
        icon: <AccountBalanceOutlined />,
        isActiveExclusion: (pathname) =>
          pathname === PATH_APP.earning.payoutAccount
        // caption:
        //   'Setup your bank account for payout, verify bank, and also withdraw money'
      },
      {
        // title: 'Payout Account',
        title: i18n._(t`Penarikan Dana`),
        path: PATH_APP.earning.payoutAccount,
        icon: <AccountBalanceWalletOutlined />
      }
    ]
  }
]

const user = [
  {
    subheader: 'Creator',
    items: [
      {
        title: 'Create Your Clicky Link!',
        path: PATH_AUTH.verifyEmail(PATH_APP.onboarding.createLink),
        icon: <StarsOutlined />
      }
    ]
  }
]

const admin = [
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Overview',
        path: PATH_APP.admin.overview,
        icon: <HomeOutlined />
      },
      {
        title: 'Transaction',
        path: PATH_APP.admin.transaction,
        icon: <HistoryOutlined />
      },
      {
        title: 'Withdrawal',
        path: PATH_APP.admin.withdrawal,
        icon: <AccountBalanceOutlined />
      },
      {
        title: 'Creator Rank',
        path: PATH_APP.admin.creatorRank,
        icon: <EqualizerOutlined />
      },
      {
        title: 'User',
        path: PATH_APP.admin.user,
        icon: <PeopleAltOutlined />
      }
    ]
  }
]

const activity = (i18n) => [
  {
    subheader: i18n._(t`Aktivitasku`),
    items: [
      {
        title: i18n._(t`Akses Konten`),
        path: PATH_APP.activity.contentAccess,
        icon: <Inventory2Outlined />
        // caption: 'List of products you bought'
      },
      {
        title: i18n._(t`Riwayat Transaksi`),
        path: PATH_APP.activity.transactionHistory,
        icon: <HistoryOutlined />
        // caption: 'Record and invoices of previous transaction'
      }
    ]
  }
]

const account = (i18n) => [
  {
    subheader: i18n._(t`Akun`),
    items: [
      {
        title: i18n._(t`Profil`),
        path: PATH_APP.account.profile,
        icon: <PersonOutlined />
        // caption: 'Adjust your basic account setup'
      },
      {
        title: i18n._(t`Keluar`),
        path: PATH_AUTH.signOut,
        icon: <MeetingRoomOutlined />
      }
    ]
  }
]

const navConfig = { clicky, admin, creator, user, activity, account }

export default navConfig
