// @mui
import { Grid } from '@mui/material'
// components
import Logo from '~/components/Logo'
import LanguageSwitcher from '~/components/LanguageSwitcher'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
export default function LogoOnlyLayout({ children }) {
  return (
    <>
      <Grid
        container
        position='absolute'
        top={0}
        left={0}
        right={0}
        width='100%'
        justifyContent='space-between'
        padding={{ xs: 3, sm: 5 }}
      >
        <Grid
          item
          xs='auto'
        >
          <Logo />
        </Grid>

        <Grid
          item
          xs='auto'
        >
          <LanguageSwitcher />
        </Grid>
      </Grid>

      {children}
    </>
  )
}
