// next
import { useRouter } from 'next/router'
import NextLink from 'next/link'
// @mui
import { Stack, Button } from '@mui/material'
// components
import LanguageSwitcher from '~/components/LanguageSwitcher'

// i18n
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object[]} props.navConfig
 * @returns {JSX.Element}
 */
export default function MenuDesktop({ navConfig }) {
  const { pathname } = useRouter()

  // i18n
  const { _ } = useLingui()

  return (
    <Stack
      direction='row'
      spacing={2}
    >
      {navConfig.map((link) => {
        const { title, isFeatured, path } = link

        return (
          <Button
            key={title}
            color={isFeatured ? 'warning' : 'primary'}
            variant={isFeatured ? 'contained' : 'text'}
            size='small'
            //
            LinkComponent={NextLink}
            href={path}
            //
            sx={{
              color: 'text.primary',
              ...(pathname === path && !isFeatured && { color: 'primary.main' })
            }}
          >
            {title}
          </Button>
        )
      })}

      <LanguageSwitcher isCompact />
    </Stack>
  )
}
