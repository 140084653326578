import { useState } from 'react'
// next
import NextLink from 'next/link'
// @mui
import { alpha } from '@mui/material/styles'
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  IconButton
} from '@mui/material'
// routes
import { PATH_AUTH, PATH_APP } from '~/routes/paths'
// hooks
import useAuth from '~/hooks/useAuth'
// components
import MyAvatar from '~/components/MyAvatar'
import MenuPopover from '~/components/MenuPopover'
// config
import { FE_HOSTNAME } from '~/config'
// i18n
import { msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

const adminAndCreatorMenuOptions = (i18n) => [
  // { text: 'Profil', href: '#' },
  { text: i18n._(t`Dashboard`), href: PATH_APP.root }
]

const userMenuOptions = (i18n) => [
  { text: i18n._(t`Akses Konten`), href: PATH_APP.activity.contentAccess },
  { text: i18n._(t`Dashboard`), href: PATH_APP.root },
  {
    text: i18n._(t`Buat Link Clickymu!`),
    href: PATH_AUTH.verifyEmail(
      `https://${FE_HOSTNAME}/${PATH_APP.onboarding.createLink}`
    )
  }
]

/**
 * Proifle icon menu in top right dashboard
 * @returns {JSX.Element}
 */
export default function AccountPopover() {
  // menu list by role
  const { user } = useAuth()

  // i18n
  const { i18n, _ } = useLingui()

  const menuOptions =
    user.role === 'admin' || user.role === 'creator'
      ? adminAndCreatorMenuOptions(i18n)
      : userMenuOptions(i18n)

  // popover handler
  const [open, setOpen] = useState(null)

  const handleOpen = (e) => {
    setOpen(e.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <MyAvatar sx={{ width: 35, height: 35 }} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          }
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant='subtitle2'
            noWrap
          >
            {user?.name}
          </Typography>
          <Typography
            variant='body2'
            sx={{ color: 'text.secondary' }}
            noWrap
          >
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {menuOptions?.map((option) => (
            <MenuItem
              key={option.text}
              href={option.href}
              component={NextLink}
              onClick={handleClose}
            >
              {option.text}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          component={NextLink}
          href={PATH_AUTH.signOut}
          onClick={handleClose}
          sx={{ margin: 1 }}
        >
          {_(msg`Keluar`)}
        </MenuItem>
      </MenuPopover>
    </>
  )
}
