// @mui
import { Box } from '@mui/material'
// components
import MainFooter from './MainFooter'
import MainHeader from './MainHeader'

// ----------------------------------------------------------------------

export { getStaticProps } from '~/i18n/utils'

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} [props.isClearNavbar=true]
 * @param {boolean} [props.isClearFooter=true]
 * @returns {JSX.Element}
 *
 */
export default function MainLayout({
  children,
  isClearNavbar = true,
  isClearFooter = true
}) {
  const minH = (isClearNavbar ? 4 : 0) + (isClearFooter ? 4 : 0)

  return (
    <>
      <MainHeader />

      <Box
        paddingTop={isClearNavbar ? { xs: 8, md: 11 } : 0}
        paddingBottom={isClearFooter ? 8 : 0}
      >
        <Box
          position='relative'
          minHeight={{
            xs: `calc(100vh - ${minH}rem)`,
            md: isClearNavbar
              ? `calc(100vh - ${minH + 3}rem)`
              : `calc(100vh - ${minH}rem)`
          }}
          // escape hatch if you want your content to fill the whole layout inner
          // direct descendants of this component can use position: absolute, inset:0
          // so that it will fill the whole layout, as it fit the viewport / screen
        >
          {children}
        </Box>
      </Box>

      <MainFooter />
    </>
  )
}
