// react
import { useState } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack
} from '@mui/material'
// icons
import { EnFlagIcon, IdFlagIcon } from '~/theme/overrides/CustomIcons'
// i18n
import { Trans } from '@lingui/macro'

// ----------------------------------------------------------------------

const LOCALE_MAP = {
  en: {
    fullLabel: 'English',
    shortLabel: 'EN',
    icon: <EnFlagIcon />
  },
  id: {
    fullLabel: 'Indonesia',
    shortLabel: 'ID',
    icon: <IdFlagIcon />
  }
}

/**
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @returns {JSX.Element}
 */
function IconWrapper({ children }) {
  return (
    <Box
      component='span'
      position='relative'
      display='block'
      height='1em'
      width='1em'
      borderRadius='1em'
      overflow='hidden'
      border='1px solid'
      borderColor='divider'
      sx={{
        '& > svg': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }
      }}
    >
      {children}
    </Box>
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.isCompact
 * @param {boolean} [props.isFullWidth=false]
 * @returns {JSX.Element}
 */

export default function LanguageSwitcher({ isCompact, isFullWidth = false }) {
  const { isReady, asPath, query, pathname, push, locale } = useRouter()

  // locale changer
  const createHandleLocaleChange = (localeKey) => async () => {
    // intentionally await this to wait locale change finish before closing the modal
    await push({ pathname, query }, asPath, { locale: localeKey })

    handleModalClose()
  }

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleModalOpen = () => setIsModalOpen(true)
  const handleModalClose = () => setIsModalOpen(false)

  return (
    <>
      <LoadingButton
        loading={!isReady}
        size={isCompact ? 'small' : 'medium'}
        startIcon={<IconWrapper>{LOCALE_MAP[locale]?.icon}</IconWrapper>}
        sx={{
          justifyContent: 'flex-start',
          color: 'text.primary'
        }}
        onClick={handleModalOpen}
        fullWidth={isFullWidth}
      >
        {LOCALE_MAP[locale]?.[isCompact ? 'shortLabel' : 'fullLabel']}
      </LoadingButton>

      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle>
          <Trans>Ganti Bahasa</Trans>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={1.5}>
            {Object.entries(LOCALE_MAP).map(([key, value]) => (
              <Button
                key={key}
                startIcon={<IconWrapper>{value.icon}</IconWrapper>}
                variant='outlined'
                size='large'
                sx={{ justifyContent: 'flex-start' }}
                onClick={createHandleLocaleChange(key)}
              >
                {value.fullLabel}
              </Button>
            ))}
          </Stack>

          <Box
            marginTop={3}
            textAlign='right'
          >
            <Button
              variant='outlined'
              size='small'
              onClick={handleModalClose}
            >
              <Trans>Batal</Trans>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
